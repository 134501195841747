import { useEffect, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import { Flex } from '../../flex/flex';
import './calendar.css';

export const Kalender = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const token = 'AIzaSyBgrwwPqYZe_20vedVgvjX-VOPWYiGbj0c';
  const id = 'a3p3privour44u0vrkb6ltimlmldqdf2@import.calendar.google.com';

  const renderEventContent = (eventInfo) => {
    const title = eventInfo.event.title.split(' - ');

    return <span className="eventTitle">{`${title[0]} - ${title[1]}`}</span>;
  };

  const view = width > 800 ? 'dayGridMonth' : 'listWeek';

  const setWindowDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const settings = {
    eventDataTransform: function (data) {
      data.url = '';
    },
    firstDay: 1,
    plugins: [dayGridPlugin, googleCalendarPlugin, listPlugin],
    googleCalendarApiKey: token,
    eventSources: [
      {
        googleCalendarId: id,
        className: 'calendarEvents'
      }
    ],
    initialView: view,
    headerToolbar: {
      left: 'title',
      center: 'today',
      right: 'prev,next'
    },
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false
    },
    eventContent: renderEventContent
  };

  return (
    <Flex layout="1" gap="xxl" alignContent="start">
      <h1>Calendar</h1>
      <div className="calendar">
        <FullCalendar {...settings} />
      </div>
    </Flex>
  );
};
