import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Flex } from '../../flex/flex';

import styles from './navigation.module.css';

export const Navigation = () => {
  const [open, setOpen] = useState(false);

  return (
    <Flex as="header" layout="1">
      <Flex
        as="nav"
        justify="space-between"
        align="center"
        layout="2"
        className={styles.nav}
        gap="sm"
      >
        <a href="/" className={styles.logo}>
          ENKODE.IO
        </a>

        <button
          className={styles.burger}
          onClick={() => setOpen(!open)}
          type="button"
        >
          <div id="hambIcon" data-expanded={open} className={styles.hambIcon}>
            <span className={styles.line1} />
            <span className={styles.line2} />
            <span className={styles.line3} />
            <span className={styles.line4} />
          </div>
        </button>

        <Flex
          direction="column"
          align="end"
          className={styles.navLinks}
          data-expanded={open}
          gap="sm md"
        >
          <NavLink
            to="/"
            className={({ isActive }) => isActive && styles.active}
          >
            Home
          </NavLink>
          <NavLink
            to="/calendar"
            className={({ isActive }) => isActive && styles.active}
          >
            Calendar
          </NavLink>
          {/* <NavLink to="/placeholder">Placeholder</NavLink> */}
          {/* <NavLink to="/placeholder">Placeholder</NavLink> */}
        </Flex>
      </Flex>
    </Flex>
  );
};
