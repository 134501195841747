import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Home } from './pages/home/home';
import { Kalender } from './pages/calendar/calendar';
import { Placeholder } from './pages/Placeholder';
import { Placeholder2 } from './pages/Placeholder-2';
import { Navigation } from './components/navigation/navigation';
import { Footer } from './components/footer/footer';
import { Flex } from './flex/flex';

const App = () => (
  <Router>
    <Flex direction="column" center gap="xl" className="container">
      <Navigation />

      <Flex as="main" layout="1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/calendar" element={<Kalender />} />
          <Route path="/Placeholder" element={<Placeholder />} />
          <Route path="/Placeholder" element={<Placeholder2 />} />
        </Routes>
      </Flex>

      <Footer />
    </Flex>
  </Router>
);

export default App;
