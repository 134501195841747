import { Flex } from '../../flex/flex';

import styles from './home.module.css';

export const Home = () => (
  <Flex layout="1" gap="xxl" alignContent="start">
    <h1>ENKODE.IO</h1>
    <Flex
      layout={{ xs: 1, sm: 2 }}
      gap={{ xs: 'md', sm: 'lg' }}
      className={styles.links}
    >
      <Flex layout="1" justify="center" alignContent="center">
        <a
          href="https://movies.enkode.io/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Movies
        </a>
      </Flex>
      <Flex layout="1" justify="center" alignContent="center">
        <a
          href="https://series.enkode.io/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Series
        </a>
      </Flex>
      <Flex layout="1" justify="center" alignContent="center">
        <a
          href="https://request.enkode.io/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Request
        </a>
      </Flex>
      <Flex layout="1" justify="center" alignContent="center">
        <a
          href="https://metrics.enkode.io/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Metrics
        </a>
      </Flex>
    </Flex>
  </Flex>
);
